import React from 'react';
import ApolloClient from 'apollo-boost';
import { gql } from 'apollo-boost';
import Layout from '../layouts/en';
import config from '../../config';
import { Link } from 'gatsby';
import iconLogin from '../assets/images/icons/icon-login.svg';
import iconLoginHover from '../assets/images/icons/icon-login-hover.svg';
import iconDown from '../assets/images/icons/icon-down.svg';
import iconGeography from '../assets/images/icons/icon-geography.svg';
import iconCaretDown from '../assets/images/icons/icon-caret-down.svg';
import iconCaretRight from '../assets/images/icons/icon-caret-right.svg';
import iconInovation from '../assets/images/icons/icon-inovation.svg';
import iconMoney from '../assets/images/icons/icon-money.svg';
import iconPlanet from '../assets/images/icons/icon-planet.svg';
import iconDownBlue from '../assets/images/icons/icon-down-blue.svg';
import iconTransform from '../assets/images/icons/icon-transform.svg';
import iconMap from '../assets/images/icons/icon-map.svg';
import iconSubmit from '../assets/images/icons/icon-submit.svg';
import iconSuitcase from '../assets/images/icons/icon-suitcase.svg';
import iconPhone from '../assets/images/icons/icon-phone.svg';
import iconAddress from '../assets/images/icons/icon-address.svg';
import iconMessage from '../assets/images/icons/icon-message.svg';
import iconRightBlue from '../assets/images/icons/icon-double-right.svg';
import iconMagnifierRemove from '../assets/images/icons/icon-magnifier-remove.svg';
import iconReporting from '../assets/images/icons/icon-reporting.svg';
import iconSustainable from '../assets/images/icons/icon-sustainable-green.svg';
import keyFactsImage from '../assets/images/key-facts-2.jpg';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import timboLogo from '../assets/images/timbo-logo.png';
import timboAfrica from '../assets/images/timbo-africa_2.jpg';
// import timboAfricaMobile from '../assets/images/timbo-africa-mobile.png';
import Scroll, { scrollTo } from './../components/Scroll';
import contactImage from '../assets/images/contact-background_2.jpg';
import contactImageMobile from '../assets/images/contact-background-mobile_2.jpg';
import Spinner from './../components/Spinner';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import fetch from 'unfetch';
import { BrowserView, MobileView } from 'react-device-detect';
import axios from 'axios';
import noImage from '../assets/images/no-image.png';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { loadByKey } from '../util/cache';
import { openUrl } from '../util/url';

const CONTACT_TABS = [
  {
    title: 'The Netherlands',
    mapUrl:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4872.9865659860625!2d4.881036!3d52.361475!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd5b42ee3519b4885!2sActive%20Capital%20Company%20B.V.!5e0!3m2!1sen!2srs!4v1568810858388!5m2!1sen!2srs',
  },
  {
    title: 'Germany',
    mapUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.476727847869!2d11.572546014928363!3d48.139613758999225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e75f4a0d537c3%3A0x133b6a976f11c1a4!2sMaffeistra%C3%9Fe%2C%2080333%20M%C3%BCnchen%2C%20Germany!5e0!3m2!1sen!2srs!4v1600803641702!5m2!1sen!2srs',
  },
];

export default class IndexPage extends React.Component {
  responsive = {
    0: { items: 1 },
    678: { items: 2 },
    1024: { items: 3 },
    1280: { items: 4 },
  };

  homepageResponsive = {
    0: { items: 1 },
    678: { items: 1 },
    1024: { items: 1 },
  };

  cache = new InMemoryCache();

  client = new ApolloClient({
    fetchOptions: { fetch },
    uri: `${process.env.GATSBY_API_URL}/graphql`,
  });

  apiEndPoint = process.env.GATSBY_API_URL;

  state = {};

  constructor(props) {
    super(props);

    this.state = {
      successMsg: false,
      fillFields: false,
      firstTab: true,
      secondTab: false,
      moreLoaded: false,
      spinnerShow: false,
      activeContactTab: 0,
      name: '',
      email: '',
      subject: '',
      message: '',
      teamMembers: [
        {
          id: 16,
          title: 'Albert Hartog',
          subtitle: 'Co-founder & advisor',
          slug: 'team/albert-hartog',
          description1:
            'Albert Hartog co-founded Active Capital Company and acts as an advisor to the investment team. Albert serves as member at the advisory board of Hendrik Veder Group.',
          description2:
            'Before founding Active Capital Company Albert was CEO at CleanLeaseFortex. Albert built this company from a single laundry shop to an industrial textile company with over 20 branches.',
          description3:
            'After his studies Albert worked in South Africa for a period of 9 years where he gained experience in the automotive and mining industries.',
        },
        {
          id: 17,
          title: 'Max Groeneveld',
          subtitle: 'Senior Advisor',
          slug: 'team/max-groeneveld',
          description1:
            'Max Groeneveld is senior advisor of Active Capital Company. Besides his role at Active Capital Company, Max is part of the senior team at EY-Parthenon. Max has been a partner at OC&C Strategy Consultants for 20 years, before it became part of EY in 2016. During the years OC&C Strategy Consultants, Max has led the Global PE practice and the Global Consumer practice and supported a variety of corporate and PE clients in a variety of industries.',
          description2:
            'At Active Capital Company, Max acts as a strategic advisor to both the Portfolio Management- and Investment Management Teams. Max is a medical doctor and holds an MBA from Stanford University.',
          description3: '',
        },
        {
          id: 1,
          title: 'Dick Zeldenthuis',
          subtitle: 'Managing Partner',
          slug: 'team/dick-zeldenthuis',
          description1:
            'Dick is Managing Partner and co-founder of Active Capital Company. Prior to Active Capital Company Dick served as CFO of JVC Benelux. In his role Dick turned the company back to profitability by leading a turnaround and improving cost control. Dick started his career at KPMG accountants in The Hague.',
          description2:
            'He has experience in reorganizing and integrating companies with expertise in financial management, valuation and due diligences.',
          description3:
            'Dick holds a Master’s degree in business administration from the Erasmus University of Rotterdam, a postgraduate degree as certified accountant (RA) and a postgraduate degree in Business Valuation from the Rotterdam School of Management.',
        },
        {
          id: 2,
          title: 'Mels Huige',
          subtitle: 'Partner',
          slug: 'team/mels-huige',
          description1:
            'Mels Huige joined Active Capital Company as Investment Director in 2013, before becoming partner in 2015. He has extensive experience in guiding merger & acquisitions projects and structuring acquisitions and valuing companies. He is responsible for acquiring new shareholdings within Active Capital Company, add-ons and exits.',
          description2:
            "Previously he worked as Director at Boer & Croon Corporate Finance. Within Boer & Croon Corporate Finance Mels focused on merger and acquisition processes within the Transport & Logistics and Industry & Trade sectors. Besides that, Mels has experience with suppliers of the maritime oil and gas industries. Mels holds a Master's degree in business economics from Maastricht University and a postgraduate degree in Business Valuation from the Rotterdam School of Management. He is board member of the Dutch Private Equity Association.",
          description3:
            '',
        },
        {
          id: 21,
          title: 'Hartwig Ostermeyer',
          subtitle: 'Partner',
          slug: 'team/hartwig-ostermeyer',
          description1:
            'Hartwig Ostermeyer is Partner at Active Capital Company. He is leading our German operations in Munich being responsible for local deal sourcing, screening of investment opportunities and portfolio management.',
          description2:
            "Hartwig started his professional career in M&A advisory in London focusing on the energy sector. After that, he worked as a project leader at Boston Consulting Group in the Munich office and gained operational experience as a manager at Sixt. Hartwig holds a 'Diplom-Ingenieur' from Technische Universität Berlin.",
        },
        {
          id: 24,
          title: 'Werner Krabbe',
          subtitle: 'Partner Special Investments',
          slug: 'team/werner-krabbe',
          description1:
            'Werner Krabbe is a partner at Active Capital Company- Special Investments and in this role he is particularly involved in transactions where the company is confronted with challenging issues. Werner focuses on the entire investment process and combines strategy with a strong dose of pragmatism.',
          description2:
            'Werner has gained broad experience at Philips, OC&C Strategy Consultants, H2 Equity, Triacta and in addition to ACC he is still active as hands-on investor / director at a company. Werner studied applied physics at the University of Twente.',
          description3: '',
        },
        {
          id: 5,
          title: 'Henkbert Kruizenga',
          subtitle: 'Partner',
          slug: 'team/henkbert-kruizenga',
          description1:
            'Henkbert is Partner at Active Capital Company. Prior to joining Active Capital Company Henkbert was Portfolio Manager at ING Bank. Henkbert has extensive experience in acquisition, growth and restructuring finance.',
          description2:
            'Within Active Capital Company Henkbert is involved with the support of Portfolio Management Teams and new investment propositions.',
          description3:
            'Henkbert holds a Master’s degree in Business Administration from Rijksuniversiteit Groningen and has taken several in-depth courses at Moody’s and Nyenrode.',
        },
        {
          id: 26,
          title: 'Boudewijn Brouwer',
          subtitle: 'Investment Director Special Investments',
          slug: 'team/boudewijn-brouwer',
          description1: `Boudewijn Brouwer is co-responsible for deal-sourcing, screening of investment opportunities and execution of (add-on) acquisitions and divestments at Active Capital Company- Special Investments. Boudewijn can quickly digest information and understand the story behind the numbers. His enthusiasm and broad interest allow him to find creative solutions when a company is confronted with challenging issues.`,
          description2: `Prior to joining Active Capital Company, Boudewijn has worked for ABN AMRO for 10 years gaining experience in Asset & Liability Management, Debt Capital Markets and Financial Restructuring. Boudewijn holds bachelor’s degrees in Medical Natural Sciences and in Business Economics, a master’s degree in Finance and is a CFA Charterholder.`,
          description3: '',
        },
        {
          id: 9,
          title: 'Leonard Spigt',
          subtitle: 'Senior Investment Manager',
          slug: 'team/leonard-spigt',
          description1:
            'Leonard Spigt works as Senior Investment Manager in the Active Capital Company team. As Senior Investment Manager at Active Capital Company Leonard is co-responsible for deal-sourcing, screening of investment opportunities and support of portfolio companies. Leonard started as an intern at Active Capital Company and completed a Master’s degree in Finance & Investments from the Erasmus University of Rotterdam',
          description2: '',
          description3: '',
        },
        {
          id: 18,
          title: 'Jorgos van Rooijen',
          subtitle: 'Senior Investment Manager',
          slug: 'team/jorgos-van-rooijen',
          description1:
            'Jorgos van Rooijen is co-responsible for deal-sourcing, screening of investment opportunities and execution of (add-on) acquisitions and divestments.',
          description2:
            'Prior to joining Active Capital Company, Jorgos worked at PwC in both the assurance and corporate finance practice where he gained broad experience with respect to finance and M&A transactions.',
          description3:
            'Jorgos holds a Master’s degree in business administration and a postgraduate degree as a certified accountant (RA) from the Erasmus University of Rotterdam.',
        },
        {
          id: 28,
          title: 'Maximiliaan Maaskant',
          subtitle: 'Senior Investment Manager',
          slug: 'team/maximiliaan-maaskant',
          description1:
            'Maximiliaan Maaskant works as Investment Manager at Active Capital Company. As Investment Manager Max is co-responsible for deal-sourcing, screening of investment opportunities and execution of (add-on) acquisitions and divestments.',
          description2:
            'Prior to joining Active Capital Company, Max worked as Investment Manager at several (family-owned) investment funds and at ING, where he gained broad experience with respect to private equity and transaction financing. Max holds a Master’s degree in Finance from the VU in Amsterdam.',
          description3: '',
        },
        {
          id: 25,
          title: 'Robin Baaij',
          subtitle: 'Investment Manager',
          slug: 'team/robin-baaij',
          description1:
            'Robin Baaij works as Investment Manager at Active Capital Company. As Investment Manager at Active Capital Company, Robin is co-responsible for deal-sourcing, screening of investment opportunities, add-ons and support of portfolio companies.',
          description2:
            'Prior to joining Active Capital Company, Robin worked as an Associate at Strategy & Amsterdam, working on deals and transformations in the (renewable) energy sector. Robin holds a Master’s degree in Aerospace Engineering from the TU Delft and an MBA from INSEAD.',
          description3: '',
        },
        {
          id: 23,
          title: 'Johannes Sensmeyer',
          subtitle: 'Investment Manager',
          slug: 'team/johannes-sensmeyer',
          description1:
            'Johannes Sensmeyer is Investment Manager at Active Capital Company in our German office in Munich. He is responsible for identifying and analysing investment opportunities and is supporting our portfolio companies mainly relating to the German market.',
          description2:
            'Johannes was Assistant to the CFO of Commerzbank AG before joining Active Capital Company. Before, he gained experience as an in-house consultant involved in M&A and digital transformation projects.',
          description3:
            'Johannes holds a Master’s degree in business administration from the University of Mannheim.',
        },
        {
          id: 34,
          title: 'Fabian Steppi',
          subtitle: 'Investment Manager',
          slug: 'team/fabian-steppi',
          description1:
            'Fabian Steppi works as Investment Manager at Active Capital Company. As Investment Manager Fabian is co-responsible for deal-sourcing, screening of investment opportunities and execution of (add-on) acquisitions and divestments.',
          description2:
            `Prior to joining Active Capital Company, Fabian worked as Investment Manager at Accursia Capital, where he gained experience in Industrials private equity. Before, he accompanied various sell- and buy-side transactions as M&A advisor at MP Corporate Finance in Vienna. Fabian holds a Bachelor's degree in Finance & Management and an MBA from Warwick Business School.`,
          description3:
            '',
        },
        {
          id: 10,
          title: 'Berend Jan van Meerten',
          subtitle: 'Investment Manager',
          slug: 'team/berend-jan-van-meerten',
          description1:
            'Berend Jan van Meerten works as Investment Associate at Active Capital Company. As associate at Active Capital Company, Berend Jan is co-responsible for screening of investment opportunities, add-ons and support of portfolio companies. Berend Jan started as an intern at Active Capital Company and completed a Master’s degree in Quantitative Finance from the Erasmus University of Rotterdam.',
          description2: '',
          description3: '',
        },
        {
          id: 30,
          title: 'Nathalie de Nijs',
          subtitle: 'Legal Counsel a.i.',
          slug: 'team/nathalie-de-nijs',
          description1:
            'Nathalie de Nijs is our Legal Counsel a.i. and in this role she is involved in all things legal for Active Capital Company, its funds and its portfolio companies.',
          description2:
            'Previously she was a candidate civil law notary specializing in corporate restructuring, mergers and acquisitions and gained wide experience in international (syndicated) lending and banking & finance at ING Bank.',
          description3: '',
        },
        {
          id: 31,
          title: 'Rivkele Kastelein',
          subtitle: 'Group Controller',
          slug: 'team/rivkele-kastelein',
          description1:
            'Rivkele Kastelein is Group Controller for Active Capital Company. In this role she is responsible for our fund administration, financial reporting and compliance.',
          description2:
            'Prior to joining Active Capital Company, Rivkele worked for BDO Accountants and gained broad experience with respect to financial reporting and compilation of financial statements. Rivkele took a career break from 2018 - 2020 to travel the world together with her husband. During this international experience she was exposed to new things on a daily base and came across a wide variety of cultures.',
          description3: '',
        },
        {
          id: 12,
          title: 'Wouter Kimman',
          subtitle: 'Investment Associate',
          slug: 'team/wouter-kimman',
          description1:
            'Wouter Kimman works as Investment Associate at Active Capital Company. Wouter is co-responsible for screening investment opportunities, deal execution and add-on acquisitions. Wouter started as an intern at Active Capital Company and completed a Master’s degree in Finance and Investments from the Erasmus University in Rotterdam.',
          description2: '',
          description3: '',
        },
        {
          id: 27,
          title: 'Miliaan Zwolle',
          subtitle: 'Investment Analyst',
          slug: 'team/miliaan-zwolle',
          description1: `Miliaan Zwolle works as Investment Analyst at Active Capital Company. As an analyst, Miliaan is co-responsible for the screening of investment opportunities, deal execution and support of portfolio companies. Miliaan started as an intern at Active Capital Company and holds a Master's degree in Quantitative Finance from the Erasmus University Rotterdam.`,
          description2: '',
          description3: '',
        },
        {
          id: 33,
          title: 'Constantijn van Wandelen',
          subtitle: 'Investment Analyst',
          slug: 'team/constantijn-van-wandelen',
          description1:
            'Constantijn van Wandelen currently works as Investment Analyst at Active Capital Company after starting as an intern in May 2022. He studied MSc International Management/CEMS and MSc Finance and Investments at the Erasmus University Rotterdam and did internships in M&A and venture capital.',
          description2:
            'Constantijn lived and studied in the Dominican Republic during his teenage years, shaping his appreciation of cultural differences and distinct (business) environments. He currently lives in Amsterdam and enjoys cooking and tasting good food, exercising, playing tennis, and reading.',
          description3: '',
        },
        {
          id: 14,
          title: 'Célaine Tates',
          subtitle: 'Office Manager',
          slug: 'team/celaine-tates',
          description1:
            'Célaine Tates is Office Manager at Active Capital Company. She is responsible for the secretarial support of the partners and for various facility services within Active Capital Company.',
          description2:
            'Célaine has worked as a Personal Assistant and Events employee at Houthoff, among others.',
          description3: '',
        }
      ],
      homepageSlider: [
        {
          id: 1,
          link: '/portfolio/?amphitec',
          text: '',
          imageName: 'homepage-amphitec',
          imageNameHover: '',
          imageClass: 'homepage-amphitec-logo',
        },
        {
          id: 2,
          link: '/portfolio/?codi_group',
          text: '',
          imageName: 'homepage-codi',
          imageNameHover: '',
          imageClass: 'homepage-codi-logo',
        },
        {
          id: 3,
          link: '/portfolio/?technobis',
          text: '',
          imageName: 'homepage-technobis',
          imageNameHover: '',
          imageClass: 'homepage-technobis-logo',
        },
      ],
      inlineStyleNavigation: {
        top: 100,
      },
      inlineStyleArrow: {
        width: 111,
        height: 111,
      },
      dataLoaded: false,
    };

    this.handleResize();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidMount() {
    const promises = [loadByKey('news', 'en'), loadByKey('portfolios', 'en')];

    Promise.all(promises)
      .then(([news, portfolio]) => {
        this.setState(
          {
            news,
            portfolio,
          },
          () => {
            this.setState({
              dataLoaded: true,
            });

            const queryParams = new URLSearchParams(this.props.location.search);
            if (queryParams.has('scroll-to')) {
              const scrollToElement = queryParams.get('scroll-to');
              scrollTo(document.getElementById(scrollToElement));
            }
          }
        );
      })
      .catch(err => console.log('ERR', err));
  }

  handleResize = () => {
    if (typeof window !== 'undefined') {
      var sliderItem = document.getElementById('slider-item-1');

      if (sliderItem !== null) {
        const me = this;
        setTimeout(() => {
          me.setState({
            inlineStyleNavigation: {
              top: sliderItem.offsetHeight + 2,
            },
          });
          me.setState({
            inlineStyleArrow: {
              width: sliderItem.offsetWidth / 4,
              height: sliderItem.offsetWidth / 4,
            },
          });
        });
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (typeof window !== 'undefined') {
      var sliderItem = document.getElementById('slider-item-1');

      if (sliderItem !== null) {
        const me = this;
        setTimeout(() => {
          me.setState({
            inlineStyleNavigation: {
              top: sliderItem.offsetHeight + 2,
            },
          });
          me.setState({
            inlineStyleArrow: {
              width: sliderItem.offsetWidth / 4,
              height: sliderItem.offsetWidth / 4,
            },
          });
        });
      }
    }
  };

  toggleDetails = (e, key) => {
    e.preventDefault();
    const elements = document.querySelectorAll('#team-member-' + key);

    if (elements.length === 0) {
      return;
    }

    this.toggleDetailsOfAllTeamMembers(elements);

    elements.forEach(element => {
      const parentElement = element.closest('.alice-carousel__stage-item');

      const childNodes = parentElement.childNodes;
      const teamImage = childNodes[0].children[0].childNodes[0];
      const teamImageHeight = teamImage.offsetHeight;

      teamImage.style.height = teamImageHeight.toString() + 'px';
      parentElement.classList.toggle('opened');
      element.classList.toggle('opened');
    });
  };

  toggleDetailsOfAllTeamMembers = skippableElements => {
    const allTeamMembers = document.querySelectorAll('.team-member-info');
    allTeamMembers.forEach(element => {
      if (skippableElements) {
        const foundSkippableElement = [...skippableElements].find(e =>
          e.isSameNode(element)
        );
        if (foundSkippableElement) {
          return;
        }
      }

      const parentElement = element.closest('.alice-carousel__stage-item');

      const childNodes = parentElement.childNodes;
      const teamImage = childNodes[0].children[0].childNodes[0];
      const teamImageHeight = teamImage.offsetHeight;

      teamImage.style.height = teamImageHeight.toString() + 'px';
      parentElement.classList.remove('opened');
      element.classList.remove('opened');
    });
  };

  handleNextTeamMemberClick = () => {
    this.toggleDetailsOfAllTeamMembers();
    this.Carousel.slideNext();
  };

  handlePrevTeamMemberClick = () => {
    this.toggleDetailsOfAllTeamMembers();
    this.Carousel.slidePrev();
  };

  setInitialItemsHeight = () => {
    setTimeout(() => {
      const slideItem1 = document.getElementById('slide-item-1');
      let initialHeight = 475;
      if (slideItem1 !== null) {
        initialHeight =
          slideItem1.childNodes[0].childNodes[0].childNodes[0].offsetHeight;
      }
      const imageWrappers = document.querySelectorAll('.team-image-wrapper');
      imageWrappers.forEach(element => {
        element.style.height = initialHeight.toString() + 'px';
      });
    }, 1000);
  };

  /**
   * Checks if object is empty.
   */
  isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  toggleMore = () => {
    this.setState({ moreLoaded: !this.state.moreLoaded });
  };

  portfolioData() {
    let portfolio = [];
    if (this.state.portfolio) {
      for (let item of this.state.portfolio) {
        if (item.category === 'current') {
          portfolio.push(item);
        }
      }
    }
    if (portfolio === undefined || portfolio.length === 0) {
      return false;
    }
    if (portfolio.length !== 0) {
      return portfolio.map((val, index) => {
        if (!val.active) {
          return;
        }
        return (
          <div
            className="single-portfolio-wrapper col-md-6 col-lg-6 col-xl-4 float-left"
            key={index}
          >
            <Link to={'/portfolio/?' + val.slug}>
              <span className="portofolio-image-wrapper">
                <img
                  src={
                    val.portfolio_image !== undefined &&
                    val.portfolio_image !== null
                      ? `${this.apiEndPoint}/${val.portfolio_image.url}`
                      : noImage
                  }
                  alt=""
                />
                <div className="image-overlay-05-black"></div>
                {val.company_logo && (
                  <img
                    className="logo-img"
                    src={`${this.apiEndPoint}/${val.company_logo.url}`}
                    alt=""
                  />
                )}
              </span>
              <span className="subtitle">{val.company_name}</span>
              <span className="title news-excerpt">{val.business}</span>
            </Link>
            <Link to={'/portfolio/?' + val.slug} className="more-info">
              Read more
            </Link>
          </div>
        );
      });
    }
    return <span className="no-data-info">No data available</span>;
  }

  divestedPortfolios() {
    let portfolio = [];
    if (this.state.portfolio) {
      for (let item of this.state.portfolio) {
        if (item.category === 'divested') {
          portfolio.push(item);
        }
      }
    }
    if (portfolio === undefined || portfolio.length === 0) {
      return false;
    }
    if (portfolio.length !== 0) {
      return portfolio.map((val, index) => {
        if (!val.active) {
          return;
        }
        return (
          <div
            className="single-portfolio-wrapper col-md-6 col-lg-6 col-xl-4 float-left"
            key={index}
          >
            <Link to={'/portfolio/?' + val.slug}>
              <span className="portofolio-image-wrapper">
                <img
                  src={
                    val.portfolio_image !== undefined &&
                    val.portfolio_image !== null
                      ? `${this.apiEndPoint}/${val.portfolio_image.url}`
                      : noImage
                  }
                  alt=""
                />
                <div className="image-overlay-05-black"></div>
                {val.company_logo && (
                  <img
                    className="logo-img"
                    src={`${this.apiEndPoint}/${val.company_logo.url}`}
                    alt=""
                  />
                )}
              </span>
              <span className="subtitle">{val.company_name}</span>
              <span className="title news-excerpt">{val.business}</span>
            </Link>
            <Link to={'/portfolio/?' + val.slug} className="more-info">
              Read more
            </Link>
          </div>
        );
      });
    }
    return <span className="no-data-info">No data available</span>;
  }

  sendContactMail = () => {
    if (
      this.state.name === '' ||
      this.state.email === '' ||
      this.state.subject === '' ||
      this.state.message === ''
    ) {
      /*
        store.addNotification({
          message: 'Please fill all fields.',
          title: 'Warning',
          type: 'warning',
          container: "top-right",
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        */
      this.setState({ fillFields: true });
      setTimeout(() => {
        this.setState({ fillFields: false });
      }, 2000);
      return;
    }

    this.setState({ spinnerShow: true });
    setTimeout(() => {
      this.setState({ spinnerShow: false });
      this.setState({ successMsg: true });
      setTimeout(() => {
        this.setState({ successMsg: false });
      }, 3000);
    }, 2000);

    return;
    const data = {
      to: ['andric.stevan@yahoo.com', 'nikola@cru.rs'],
      from: this.state.email,
      replyTo: this.state.email,
      subject: this.state.subject,
      text: this.state.message,
      html:
        this.state.name +
        ' sent you a message: <br><p>' +
        this.state.message +
        '</p>',
    };

    this.setState({ spinnerShow: true });
    axios
      .post(`${this.apiEndPoint}/email/`, data)
      .then(response => {
        store.addNotification({
          message: 'Message has been sent successfully.',
          title: 'Success',
          type: 'success',
          container: 'top-right',
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      })
      .catch(err => {
        console.error(err);
        store.addNotification({
          message: 'Something went wrong, please try again later.',
          title: 'Error',
          type: 'danger',
          container: 'top-right',
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      })
      .finally(() => {
        this.setState({ spinnerShow: false });
      });
  };

  notificationMesagge() {
    return (
      <div className="success-mesagge">
        <p>Confirmed. Your message has been sent.</p>
        <p>We will reply as soon as possible.</p>
      </div>
    );
  }

  filedsMesagge() {
    return (
      <div className="fields-mesagge">
        <p>Please fill all fields.</p>
      </div>
    );
  }

  /**
   * It generates list of news items to be shown.
   */
  listItems() {
    let items = this.state.news;
    let options = { month: 'long', day: '2-digit', year: 'numeric' };
    if (items === undefined || items.length === 0) {
      return false;
    }

    return (
      <div className="row news-container">
        {items.map((val, index) => {
          if (index > 5) {
            return;
          }

          return (
            <Link
              to={'/news-details/?' + val.slug}
              className="col-lg-6 news-box"
              key={index}
            >
              <div className="float-left news-image">
                <img
                  width="100%"
                  src={
                    val.heading_image !== undefined &&
                    val.heading_image !== null
                      ? `${this.apiEndPoint}/${val.heading_image.url}`
                      : noImage
                  }
                  alt=""
                />
              </div>
              <div className="large-padding news-text">
                <h5 className="news-title">{val.img_heading}</h5>
                <div className="news-published">
                  <div
                    className="cursor--pointer"
                    onClick={e => openUrl(val.source_url)}
                  >
                    {val.source || 'Press release'},{' '}
                    {new Intl.DateTimeFormat('en-GB', options).format(
                      new Date(val.published)
                    )}
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </Link>
          );
        })}
      </div>
    );
  }

  render() {
    const { dataLoaded, activeContactTab } = this.state;
    return (
      <Layout dataLoaded={dataLoaded}>
        <Spinner show={this.state.spinnerShow} />
        <header className="homepage-header-wrapper" id="header">
          <span id="homepage-indicator"></span>

          <AliceCarousel
            responsive={this.homepageResponsive}
            dotsDisabled={true}
            autoPlay={true}
            fadeOutAnimation={true}
            autoPlayInterval={5000}
            playButtonEnabled={false}
            buttonsDisabled={true}
            preventEventOnTouchMove={false}
            stopAutoPlayOnHover={false}
            mouseDragEnabled={false}
            duration={2000}
          >
            {
              (this.items = this.state.homepageSlider.map((item, key) => (
                <div className={`masthead masthead-${item.id}`} key={key}>
                  <div className="page-wrapper absolute-wrapper-header-logo">
                    {/* <span className="background-image-text">Background image</span> */}
                    <span className="text-right homepage-mobile-hidden">
                      <a href={item.link} rel="noopener noreferrer">
                        {/*<img className="left-spacing-medium default-icon-state" src={require(`../assets/images/icons/icon-${item.imageName}.svg`)} alt=""/>*/}
                        {/*<img className="left-spacing-medium hover-icon-state" src={require(`../assets/images/icons/icon-${item.imageNameHover}.svg`)} alt=""/>*/}
                        <img
                          className={item.imageClass}
                          src={require(`../assets/images/homepage-icons/${item.imageName}.png`)}
                          alt=""
                        />
                      </a>
                    </span>
                    <span className="clearfix"></span>
                  </div>
                </div>
              )))
            }
          </AliceCarousel>
          <div className="container d-flex align-items-center homepage-absolute-wrapper">
            <div className="center-block text-center col-sm-12">
              <h1 className="mx-auto my-0 homepage-main-text">
                We are a hands-on investment company realizing full potential
                through active transformation.
              </h1>
              <div className="header-buttons-wrapper">
                <Scroll type="id" element="our-work">
                  <a
                    href="#our-work"
                    className="button-link button-white-wrapper"
                  >
                    <button className="button button-white">
                      Our approach
                      <img src={iconCaretDown} alt="" />
                    </button>
                  </a>
                </Scroll>

                <Link
                  to="/investment-focus"
                  className="button-link button-orange-wrapper"
                >
                  <button className="button button-orange">
                    Investment focus
                    <img src={iconCaretRight} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="header-quick-links page-wrapper absolute-wrapper-header-quick-links container page-wrapper">
            <span className="three-column custom-hompage-three-column homepage-mobile-hidden">
              <a
                href="https://fundrbird.com/login?agent_slug=active-capital"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="right-spacing-medium default-icon-state"
                  src={iconLogin}
                  alt=""
                />
                <img
                  className="right-spacing-medium hover-icon-state"
                  src={iconLoginHover}
                  alt=""
                />
                Investor log in
              </a>
            </span>
            <span className="three-column text-center">
              <Scroll type="id" element="about">
                <img
                  className="default-icon-state icon-down"
                  src={iconDown}
                  alt=""
                />
              </Scroll>
            </span>
            <span className="clearfix"></span>
          </div>
        </header>

        <section className="container content-wrapper" id="about">
          <div className="homepage-title text-center">
            <h2 className="text-left">
              Our beliefs: Passion, Entrepreneurship, Result.
            </h2>
            <span className="horizontal-line gray-background homepage-line homepage-desktop-hidden"></span>
            <div className="col-sm-6 text-left float-left custom-right-padding">
              <p>
                Active Capital Company invests in small- and medium sized
                companies headquartered in the Netherlands or Germany with
                revenues between € 10M - € 100M, which are active in production,
                (technical) wholesale or business services.
              </p>
            </div>
            <div className="col-sm-6 text-left float-left custom-left-spacing">
              <p>
                We are founded and inspired by entrepreneurs with a passion for
                industrial environments leading to superior results through our
                three main drivers: Geographic expansion, sustainability and
                innovation.
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
          <span className="horizontal-line gray-background homepage-line homepage-mobile-hidden"></span>
          <div className="about-wrapper">
            <div className="col-md-6 text-left float-left custom-right-spacing homepage-disable-padding">
              <div className="gray-background medium-padding">
                <h3>
                  Sustainable returns for our stakeholders are based on three
                  value drivers.
                </h3>
              </div>
            </div>
            <div className="col-sm-6 float-left custom-left-spacing">
              <img
                className="about-geographic"
                src={iconGeography}
                alt="Geographic expansion"
              />
              <h4 className="fs-1_5rem">
                Geographic
                <br />
                expansion
              </h4>
              <p>
                We expand the geographical scope through new facilities and
                sales teams across the globe
              </p>
            </div>
            <div className="clearfix"></div>
            <div className="col-sm-6 float-left custom-right-spacing custom-top-spacing">
              <img src={iconInovation} alt="Innovation" />
              <h4 className="fs-1_5rem">Innovation</h4>
              <p>
                We accelerate growth by investing in innovation through add-ons
                and product development
              </p>
            </div>
            <div className="col-sm-6 float-left custom-left-spacing custom-top-spacing">
              <img src={iconPlanet} alt="Impact" />
              <h4 className="fs-1_5rem">Impact</h4>
              <p>
                We adopt sustainability characteristics to obtain higher
                profitability and invest in people, environment and culture
              </p>
            </div>
            <div className="clearfix"></div>
          </div>

          <div className="investment-criteria">
            <Link to="/investment-focus">
              <img src={iconRightBlue} alt="Investment focus" />
              View our investment focus
            </Link>
          </div>

          <div className="key-facts-wrapper">
            <div className="col-lg-6 float-left key-facts-image">
              <img
                src={keyFactsImage}
                alt="Key facts"
                className="right-padding"
              />
            </div>

            <div className="col-lg-6 float-left">
              <h3>Key facts</h3>
              <span className="horizontal-line gray-background no-side-margin"></span>
              <h4>
                3711 <br className="visible-mobile" />
                <sup>employees</sup>
              </h4>
              <p>
                working in 10 companies in our current portfolio across 11
                countries
              </p>
              <h4>
                € 184M
                <br className="visible-mobile" />
                <sup> assets under management</sup>
              </h4>
              <p>across 4 different funds</p>
            </div>

            <div className="clearfix"></div>
          </div>

          <div className="our-work-wrapper" id="our-work">
            <div className="col-sm-12">
              <h3 className="top-separator">The ACC way</h3>
              <span className="horizontal-line gray-background no-side-margin"></span>
            </div>
            <div className="our-work-wrapper-section">
              <div className="col-md-6 col-lg-4 float-left home-page-box">
                <label htmlFor="post-1" className="link-label">
                  <span className="blue-color mobile-hidden">
                    <strong>Phase 1</strong>
                  </span>
                  <span className="horizontal-line blue-background no-side-margin mobile-hidden"></span>
                  <img src={iconMoney} alt="Phase 1" />
                  <input
                    type="checkbox"
                    className="read-more-state"
                    id="post-1"
                  />
                  <div className="width-80 read-more-wrap">
                    <div className="our-work-phase-heading">
                      <h4>Transaction</h4>
                      <p>Why and how we decide on investment</p>
                    </div>
                    <div className="read-more-target">
                      <span className="horizontal-line gray-background no-side-margin"></span>
                      <ul>
                        <li
                          onClick={() => this.handleListCollapse(1)}
                          className="list-wrapper-1"
                        >
                          <span className="list-basic-1">
                            Examination of the opportunity by our investment
                            criteria.
                          </span>
                          <span className="list-detailed-1 hidden">
                            Thorough examination of the opportunity by our{' '}
                            <a className="orange-link" href="/investment-focus">
                              investment criteria
                            </a>
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(2)}
                          className="list-wrapper-2"
                        >
                          <span className="list-basic-2">
                            Execution of due diligence.
                          </span>
                          <span className="list-detailed-2 hidden">
                            Execution of due diligence by our own
                            <Scroll type="id" element="team">
                              <a href="?scroll-to=team" className="orange-link">
                                {' '}
                                team{' '}
                              </a>
                            </Scroll>{' '}
                            with support of our preferred
                            <Scroll type="id" element="portfolio">
                              <a
                                href="?scroll-to=portfolio"
                                className="orange-link"
                              >
                                {' '}
                                partners{' '}
                              </a>
                            </Scroll>
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(3)}
                          className="list-wrapper-3"
                        >
                          <span className="list-basic-3">
                            Presentation of non-binding offer.
                          </span>
                          <span className="list-detailed-3 hidden">
                            Presentation of the non-binding offer and commercial
                            due diligence to discuss strategy going forward
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(4)}
                          className="list-wrapper-4"
                        >
                          <span className="list-basic-4">
                            Drafting of our 100 days plan.
                          </span>
                          <span className="list-detailed-4 hidden">
                            Drafting of our 100 days plan in close cooperation
                            with seller and industry experts
                          </span>
                        </li>
                      </ul>
                    </div>
                    <label
                      htmlFor="post-1"
                      className="read-more-trigger"
                    ></label>
                  </div>
                </label>
              </div>

              <div className="col-md-6 col-lg-4 float-left home-page-box">
                <label htmlFor="post-2" className="link-label">
                  <span className="orange-color mobile-hidden">
                    <strong>Phase 2</strong>
                  </span>
                  <span className="horizontal-line orange-background no-side-margin mobile-hidden"></span>
                  <img src={iconTransform} alt="Phase 2" />
                  <input
                    type="checkbox"
                    className="read-more-state"
                    id="post-2"
                  />
                  <div className="width-80 read-more-wrap">
                    <div className="our-work-phase-heading">
                      <h4>Transformation</h4>
                      <p>Plan and Execution during our holding period</p>
                    </div>
                    <div className="read-more-target">
                      <span className="horizontal-line gray-background no-side-margin"></span>
                      <ul>
                        <li
                          onClick={() => this.handleListCollapse(5)}
                          className="list-wrapper-5"
                        >
                          <span className="list-basic-5">
                            Spending time on our portfolio companies.
                          </span>
                          <span className="list-detailed-5 hidden">
                            Spend four days per week on our
                            <Scroll type="id" element="portfolio">
                              <a
                                href="?scroll-to=portfolio"
                                className="orange-link"
                              >
                                {' '}
                                portfolio companies{' '}
                              </a>
                            </Scroll>{' '}
                            to implement the ACC standards, creating capacity
                            and focus to realize growth
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(6)}
                          className="list-wrapper-6"
                        >
                          <span className="list-basic-6">
                            Accelerate growth.
                          </span>
                          <span className="list-detailed-6 hidden">
                            Accelerate growth by investing in innovation through
                            add-ons and product development
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(7)}
                          className="list-wrapper-7"
                        >
                          <span className="list-basic-7">
                            Expand geographical scope.
                          </span>
                          <span className="list-detailed-7 hidden">
                            Expand geographical scope through new facilities and
                            sales teams across the globe
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(8)}
                          className="list-wrapper-8"
                        >
                          <span className="list-basic-8">
                            Transform company culture.
                          </span>
                          <span className="list-detailed-8 hidden">
                            Transform company culture with strong focus on
                            sustainability
                          </span>
                        </li>
                      </ul>
                    </div>
                    <label
                      htmlFor="post-2"
                      className="read-more-trigger"
                    ></label>
                  </div>
                </label>
              </div>

              <div className="col-md-6 col-lg-4 float-left home-page-box">
                <label htmlFor="post-3" className="link-label">
                  <span className="soft-red-color mobile-hidden">
                    <strong>Phase 3</strong>
                  </span>
                  <span className="horizontal-line soft-red-background no-side-margin mobile-hidden"></span>
                  <img src={iconMap} alt="Phase 3" />
                  <input
                    type="checkbox"
                    className="read-more-state"
                    id="post-3"
                  />
                  <div className="width-80 read-more-wrap">
                    <div className="our-work-phase-heading">
                      <h4>Positioning & exit</h4>
                      <p>
                        Plan and Execution of
                        <br />
                        sale process
                      </p>
                    </div>
                    <div className="read-more-target">
                      <span className="horizontal-line gray-background no-side-margin"></span>
                      <ul>
                        <li
                          onClick={() => this.handleListCollapse(9)}
                          className="list-wrapper-9"
                        >
                          <span className="list-basic-9">
                            Focus on exit positioning.
                          </span>
                          <span className="list-detailed-9 hidden">
                            Throughout the holding period, focus every strategic
                            decision on exit positioning
                          </span>
                        </li>
                        <li
                          onClick={() => this.handleListCollapse(10)}
                          className="list-wrapper-10"
                        >
                          <span className="list-basic-10">
                            Determine optimal exit moment.
                          </span>
                          <span className="list-detailed-10 hidden">
                            Determine optimal exit moment of
                            <Scroll type="id" element="portfolio">
                              <a
                                href="?scroll-to=portfolio"
                                className="orange-link"
                              >
                                {' '}
                                portfolio{' '}
                              </a>
                            </Scroll>
                            company:
                            <ul className="the-way-list">
                              <li>Organization</li>
                              <li>Culture</li>
                              <li>Growth</li>
                              <li>Innovation</li>
                            </ul>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <label
                      htmlFor="post-3"
                      className="read-more-trigger"
                    ></label>
                  </div>
                </label>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </section>

        <section
          className="container content-wrapper business-invested mobile-padding"
          id="portfolio"
        >
          <h3 className="top-separator">Our portfolio</h3>
          <div className="tabs-wrapper">
            <div
              className={this.state.firstTab ? 'tab-link active' : 'tab-link'}
              onClick={this.showTab1}
            >
              Current investments
            </div>
            <span className="vertical-separator homepage-mobile-hidden"></span>
            <hr className="short-horizontal-line" />
            <div
              className={this.state.secondTab ? 'tab-link active' : 'tab-link'}
              onClick={this.showTab2}
            >
              Divested
            </div>
          </div>
          <div className="clearfix"></div>
          <span className="horizontal-line gray-background no-side-margin"></span>

          <div className="row">
            <input
              type="checkbox"
              className="read-more-state"
              id="portfolio-1"
            />
            <div
              className={
                'current-investment-wrapper read-more-wrap' +
                (!this.state.firstTab ? ' hide' : '') +
                (this.state.moreLoaded ? ' more-loaded' : '')
              }
            >
              {this.portfolioData()}
              <label
                htmlFor="portfolio-1"
                className="read-more-trigger"
                onClick={this.toggleMore}
              ></label>
            </div>

            <div
              className={
                'divested-wrapper' + (!this.state.secondTab ? ' hide' : '')
              }
            >
              {this.divestedPortfolios()}
            </div>
          </div>
          <div className="clearfix"></div>
        </section>
        <section className="team-title-container" id="team">
          <h3 className="text-center top-separator section-spaces-bottom">
            Meet our team
          </h3>
        </section>
        <section className="team-wrapper col-sm-12">
          <AliceCarousel
            responsive={this.responsive}
            dotsDisabled={true}
            buttonsDisabled={true}
            ref={el => (this.Carousel = el)}
            onInitialized={this.setInitialItemsHeight}
            onResized={this.setInitialItemsHeight}
          >
            {
              (this.items = this.state.teamMembers.map((item, key) => (
                <div
                  id={`slide-item-` + key}
                  onClick={e => this.toggleDetails(e, key)}
                  key={key}
                >
                  <a href="#" key={key}>
                    <div className="team-image-wrapper">
                      <img
                        src={require(`../assets/images/team/team-${item.id}.jpg`)}
                        alt={item.title}
                        id={`slider-item-` + key}
                      />
                    </div>
                    <div className="title">{item.title}</div>
                    <div className="subtitle">{item.subtitle}</div>
                  </a>
                  <div className="team-member-info" id={`team-member-${key}`}>
                    <span className="member-info">
                      <p className="black-color">{item.description1}</p>
                      <p>{item.description2}</p>
                      <p>{item.description3}</p>
                      {item.description4 && <p>{item.description4}</p>}
                    </span>
                  </div>
                </div>
              )))
            }
          </AliceCarousel>

          <div
            className="navigation-buttons-container"
            style={this.state.inlineStyleNavigation}
          >
            <button
              className="alice-carousel__prev-btn"
              style={this.state.inlineStyleArrow}
              onClick={() => this.handlePrevTeamMemberClick()}
            >
              <p className="alice-carousel__prev-btn-item">
                <span></span>
              </p>
            </button>
            <button
              className="alice-carousel__next-btn"
              style={this.state.inlineStyleArrow}
              onClick={() => this.handleNextTeamMemberClick()}
            >
              <p className="alice-carousel__next-btn-item">
                <span></span>
              </p>
            </button>
          </div>
        </section>

        {/* End of team section */}

        <section
          className="container content-wrapper responsible-investments-title-container"
          id="responsible-investments"
        >
          <h3 className="text-center top-separator section-spaces-bottom">
            Reponsible Investments
          </h3>
          <h4 className="text-center section-spaces-bottom">
            At Active Capital Company we consider the impact of our portfolio
            companies on the environment and society important. We separate
            impact topics across three pillars:
          </h4>
        </section>
        <section className="container content-wrapper responsible-investments-wrapper">
          <div className="row">
            <div className="responsible-investments-box col-md-4">
              <div className="responsible-investments-box-inner-wrapper gray-background">
                <img
                  src={iconReporting}
                  className="responsible-investments-icons"
                />
                <span className="responsible-investments-title">
                  Reporting & legislation
                </span>
                <span className="responsible-investments-text">
                  We encourage our companies to becoming more transparent on
                  Environmental, Social and Governance ("ESG") related topics
                  and other non-financial considerations
                </span>
              </div>
            </div>
            <div className="responsible-investments-box col-md-4">
              <div className="responsible-investments-box-inner-wrapper gray-background">
                <img
                  src={iconMagnifierRemove}
                  className="responsible-investments-icons"
                />
                <span className="responsible-investments-title">
                  Impact reduction
                </span>
                <span className="responsible-investments-text">
                  Reduction of the impact of the current business on ESG-related
                  topics such as CO2 and water usage. Where relevant, management
                  and ACC will be supported by advisors or other third parties
                </span>
              </div>
            </div>
            <div className="responsible-investments-box col-md-4">
              <div className="responsible-investments-box-inner-wrapper gray-background">
                <img
                  src={iconSustainable}
                  className="responsible-investments-icons"
                />
                <span className="responsible-investments-title">
                  Value proposition
                </span>
                <span className="responsible-investments-text">
                  Transition business models to becoming more sustainable and
                  resilient for future market dynamics. At the initial stage of
                  a portfolio investment, sustainability characteristics are
                  considered cornerstones of the company’s strategy going
                  forward
                </span>
              </div>
            </div>
          </div>

          <Link
            to="https://activecapitalcompany.nl/ACC_ESG-related_disclosures_Nov_2022.pdf"
            target="_blank"
            className="more-info"
          >
            View our ESG-related disclosures
          </Link>
        </section>

        {/* Start of Timbo section */}

        <section
          className="container content-wrapper corporate-wrapper col-sm-12"
          id="corporate-responsibility"
        >
          <div className="">
            <div className="col-md-6 float-left corporate-first-wrapper">
              <div className="timbo-image-wrapper desktop-hidden">
                <img src={timboAfrica} alt="Timbo Africa Foundation" />
              </div>
              <img
                src={timboLogo}
                alt="Timbo Africa Foundation"
                width="160px"
                className="timbo-logo"
              />
              <br />
              <h3>Timbo Africa Foundation</h3>
              <br />
              <p>
                Timbo Foundation is committed to protect the wildlife in Africa
                by creating corridors between the various existing natural areas
                so that the migration of wildlife becomes possible. In addition,
                she tries to involve the local youth through research, training
                and education in order to give them the opportunity for a career
                in nature conservation.
              </p>
              <p>
                The Foundation operates primarily in Botswana and South Africa
                but also raises funds directly for support of cancer research in
                The Netherlands.
              </p>
              <br />
              <Link to="/corporate-responsibility" className="button-link">
                <button className="button button-blue">
                  Learn more
                  <img src={iconCaretRight} alt="" />
                </button>
              </Link>
              <br />
              <br />
            </div>
            <div className="col-md-6 float-left timbo-image-wrapper mobile-hidden">
              <img src={timboAfrica} alt="Timbo Africa Foundation" />
            </div>
            <div className="clearfix"></div>
          </div>
        </section>

        <section
          className="container content-wrapper news-wrapper col-sm-12 mobile-padding "
          id="news"
        >
          <h3 className="top-separator section-spaces-bottom">
            Our latest
            <br />
            developments
          </h3>
          <div className="view-all-news top-separator-large custom-view-all-news-link">
            <p>
              <Link to="/news-overview">View all news items</Link>
            </p>
          </div>
          <div className="clearfix"></div>
          <span className="horizontal-line gray-background no-side-margin"></span>
          <div className="bottom-spacing">
            {this.listItems()}
            <Link
              className="view-all-news-and-articles top-separator-large"
              to="/news-overview"
            >
              <p>View all news and articles</p>
            </Link>
            <div className="clearfix"></div>
          </div>
        </section>

        <section className="contact-wrapper" id="contact">
          <span className="contact-info-text filter--text-shadow--dark text-desktop-hidden">
            Contact us for any questions you might have
          </span>
          <div className="filter--darken-image">
            <img
              src={contactImage}
              alt="Contact"
              className="background-image-contact-desktop background-image text-mobile-hidden"
            />
          </div>
          <div className="filter--darken-image">
            <img
              src={contactImageMobile}
              alt="Contact"
              className="background-image text-desktop-hidden"
            />
          </div>
          <div className="container page-wrapper contact-info-wrapper">
            <div className="col-md-6 float-left contact-form-wrapper">
              <div className="contact-info-tabs">
                {CONTACT_TABS.map((tab, index) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        activeContactTab === index ? 'active' : ''
                      } contact-tab-link`}
                      onClick={this.onTabChange(index)}
                    >
                      {tab.title}
                    </div>
                  );
                })}
              </div>
              <div>
                <div className="google-map">
                  <iframe
                    title="Google Map"
                    src={CONTACT_TABS[activeContactTab].mapUrl}
                    width="100%"
                    height="450"
                    frameBorder="0"
                  ></iframe>
                </div>

                <div className="contact-form">
                  <form id="contact-form">
                    <input
                      type="text"
                      className="name-field"
                      id="name"
                      name="name"
                      placeholder="Your name"
                      value={this.state.name}
                      required
                      onChange={this.handleNameChange}
                    />

                    <input
                      type="text"
                      className="email-field"
                      id="email"
                      name="email"
                      placeholder="Your email address"
                      value={this.state.email}
                      required
                      onChange={this.handleEmailChange}
                    />

                    <input
                      type="text"
                      className="subject-field"
                      id="subject"
                      name="subject"
                      placeholder="The subject"
                      value={this.state.subject}
                      required
                      onChange={this.handleSubjectChange}
                    />

                    <textarea
                      className="message-field"
                      id="message"
                      name="message"
                      placeholder="Your message"
                      value={this.state.message}
                      required
                      onChange={this.handleMessageChange}
                    ></textarea>

                    <button
                      className="button button-blue"
                      type="button"
                      onClick={this.sendContactMail}
                    >
                      <span className="button-text">Send message</span>
                      <img src={iconSubmit} alt="" />
                    </button>
                  </form>
                </div>
                {this.state.successMsg ? (
                  this.notificationMesagge()
                ) : (
                  <div></div>
                )}
                {this.state.fillFields ? this.filedsMesagge() : <div></div>}
              </div>
            </div>

            <div className="col-md-6 float-left no-left-padding">
              <div className="contact-info">
                <span className="contact-info-text filter--text-shadow--dark text-mobile-hidden">
                  Contact us for any questions you might have
                </span>
                <p>
                  <img
                    className="contact-info-left-section"
                    src={iconSuitcase}
                    alt=""
                  />
                  <span className="contact-info-right-section">
                    Active Capital Company B.V.
                  </span>
                  <span className="clearfix"></span>
                </p>
                <p>
                  <img
                    className="contact-info-left-section"
                    src={iconAddress}
                    alt=""
                  />
                  <span className="contact-info-right-section">
                    <span className="contact-info-right-title">
                      The Netherlands
                    </span>
                    <a
                      className="contact-info-right-link"
                      href="https://goo.gl/maps/mUFBVCqkTefWpyqu9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vossiusstraat 13A
                      <span className="custom-align">1071 AC Amsterdam</span>
                    </a>
                    <br />
                    <span className="contact-info-right-border"></span>
                    <br />
                    <span className="contact-info-right-title">Germany</span>
                    <a
                      className="contact-info-right-link"
                      href="https://goo.gl/maps/gHVZcrpjRnnpmcBh9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Maffeistraße 3
                      <span className="custom-align">80333 München</span>
                    </a>
                  </span>

                  <span className="clearfix"></span>
                </p>

                <BrowserView renderWithFragment>
                  <p>
                    <img
                      className="contact-info-left-section"
                      src={iconPhone}
                      alt=""
                    />
                    <span className="contact-info-right-section">
                      <span className="contact-info-right-title">NL: </span>{' '}
                      {config.companyPhoneNumber}
                      <br />
                      <br />
                      <span className="contact-info-right-title">
                        DE:{' '}
                      </span>{' '}
                      {config.companyPhoneNumberGermany}
                    </span>
                    <span className="clearfix"></span>
                  </p>
                </BrowserView>
                <MobileView renderWithFragment>
                  <p>
                    <img
                      className="contact-info-left-section"
                      src={iconPhone}
                      alt=""
                    />
                    <span className="contact-info-phone-numbers">
                      <a
                        className="contact-info-right-section contact-info-phone"
                        href={config.companyPhoneUrl}
                      >
                        <span className="contact-info-right-title">NL: </span>
                        {config.companyPhoneNumber}
                      </a>
                      <a
                        className="contact-info-right-section"
                        href={config.companyPhoneUrlGermany}
                      >
                        <span className="contact-info-right-title">DE: </span>
                        {config.companyPhoneNumberGermany}
                      </a>
                    </span>
                    <span className="clearfix"></span>
                  </p>
                </MobileView>

                <p>
                  <img
                    className="contact-info-left-section"
                    src={iconMessage}
                    alt=""
                  />
                  <span className="contact-info-right-section">
                    <a href={'mailto:' + config.companyEmail}>
                      {config.companyEmail}
                    </a>
                  </span>
                  <span className="clearfix"></span>
                </p>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </section>
        <Scroll type="id" element="header">
          <div className="back-to-top-wrapper">
            <a href="?scroll-to=header">
              <i className="fas fa-angle-double-up"></i>
              <br />
              <span className="back-to-top">Back to top</span>
            </a>
          </div>
        </Scroll>
      </Layout>
    );
  }

  showTab1 = () => {
    this.setState({
      firstTab: true,
      secondTab: false,
    });
  };

  showTab2 = () => {
    this.setState({
      firstTab: false,
      secondTab: true,
    });
  };

  onTabChange = index => () => {
    this.setState({
      activeContactTab: index,
    });
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  handleSubjectChange = e => {
    this.setState({ subject: e.target.value });
  };

  handleMessageChange = e => {
    this.setState({ message: e.target.value });
  };

  handleListCollapse = index => {
    var listBasic = document.querySelector('.list-basic-' + index);
    var listDetailed = document.querySelector('.list-detailed-' + index);
    var listWrapper = document.querySelector('.list-wrapper-' + index);

    listBasic.classList.toggle('hidden');
    listDetailed.classList.toggle('hidden');
    listWrapper.classList.toggle('opened');
  };
}
